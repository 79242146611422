<template>
  <div class="w-full mb-4 bg-white rounded-xl shadow-lg text-gray-600">
    <div
      class="px-6 py-2 rounded-lg flex justify-between items-center cursor-pointer p-3"
      @click="toggleAccordion"
      style="border-bottom: gray solid 1px"
    >
      <p class="text-dark text-left">
        <b>Acuso de recibo y prueba de entrega</b>
      </p>
      <span
        class="h-5 w-5 m-2 flex justify-center items-center transition"
        :class="{ 'transform rotate-180': accordionOpen }"
      >
        <img src="@/assets/icons/arrow-down.svg" alt="" />
      </span>
    </div>
    <div v-if="accordionOpen" class="py-3 px-6 pb-6">
      <div class="py-12" v-if="legend">
        <b class="text-center text-2xl mx-auto text-gray-400">{{legend}}</b>
      </div>
      <div v-if="loading" class="flex justify-center items-center">
        <a-spin>
          <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
        </a-spin>
      </div>
      <div class="flex flex-col gap-2" v-if="attachments.length > 0 || loading">
        <div class="w-full grid grid-cols-1 gap-2">
          <BaseDownload
            v-for="(file, index) in attachments"
            label="Acuso de recibo y prueba de entrega"
            :value="file.name_file"
            :key="index"
            :url_download="file.id_storage + separator"
          />
        </div>
      </div>
      <div v-else class="text-gray-400 mt-2">No hay archivos adjuntos</div>
      <a-form-model
        ref="formModel"
        :rules="rules"
        :model="form"
        @submit="onSubmit"
        @submit.native.prevent
        class="mt-5"
        v-if="!loading"
      >
        <a-form-model-item prop="files">
          <DragDrop
            label="Anexar archivos de soporte:"
            buttonLabel="SELECCIONE SUS ARCHIVOS"
            id="support_files"
            :fileList="form.files"
            @files-handler="setFiles"
            :required="true"
            maxMB="20"
          />
        </a-form-model-item>
        <a-form-item class="flex gap-6 justify-center my-5">
          <a-button
            class="mr-3"
            :loading="loading"
            type="primary"
            shape="round"
            html-type="submit"
            >GUARDAR</a-button
          >
          <a-button
            class="ml-3"
            :loading="loading"
            type="danger"
            shape="round"
            ghost
            @click="onCancel"
            >CANCELAR</a-button
          >
        </a-form-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    references: {
      type: String,
      default: () => [],
    },
    complaint: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    count: 0,
    page: 1,
    pageSize: 10,
    pageSizeOptions: ["10", "20", "50"],
    accordionOpen: false,
    attachments: [], 
    loading: true,
    pre_search: false,
    loading: false,
    attachments: [],
    legend: null,
    separator: '_id_storage__SFC',
    form: {
      files: [],
    },
    rules: {
      files: [
        {
          required: true,
          message: "Este campo es obligatorio",
          trigger: "blur",
        },
      ],
    },
  }),
  watch: {
    page: function (newVal, oldVal) {
      this.fetchAnnexes();
    },
  },
  methods: {
    toggleAccordion() {
      if (!this.pre_search){
        this.fetchAnnexes()
        this.pre_search = !this.pre_search;
      }
      this.accordionOpen = !this.accordionOpen;
    },
    onSubmit(e) {
      e.preventDefault();

      this.$refs.formModel.validate(async (valid) => {
        if (valid) {
          let form = {
            codigo_queja: this.complaint.codigo_queja,
            tipo: 99,
          };
          this.loading = true;
          let { data, error } = await this.$api.setAnexos(
            form,
            this.form.files
          );

          if (error) 
            this.legend = "Ocurrió un error con la consulta";
          else 
            this.showToast("success", "Se almacenó el documento correctamente");

          this.loading = false;
          this.form.files = [];
          this.fetchAnnexes();
        } else {
          return false;
        }
      });
    },
    onCancel() {
      this.$refs.formModel.resetFields();
      this.accordionOpen = false;
    },
    setFiles(files) {
      this.form.files = files;
    },
    async fetchAnnexes(){
      this.loading = true;
      this.attachments = [];

      let query = `?page_size=${this.pageSize}&page=${this.page}&codigo_queja=${this.complaint.codigo_queja}&reference_storage=${this.references}`;
      
      let { error, data } = await this.$api.getAttachmentsAll(query);
      

      if (error) this.legend = "Ocurrió un error con la consulta";
      
      if (data) {
        this.attachments = data.results;
        this.count = data.total;
      }

      // this.count = this.attachments.length
      this.loading = false;
    },
    onShowSizeChange(page, page_size) {
      this.page = page;
      this.pageSize = page_size;
      this.fetchAnnexes()
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
  },
};
</script>

<style></style>
